import DeliveryAddressForm from '@components/DeliveryAddressForm';
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { transformDeliveryAddressDtoToAddress } from 'src/hook-form-helper';
import useApi from 'src/hooks/useApi';
import useSnackbarGlobal from 'src/hooks/useSnackbarGlobal';
import { useSetTransportInfo } from 'src/hooks/useTransportOptions';
import ApiService from 'src/modules/api-service';
import { dateInFuture } from 'src/modules/date-helpers';
import { getLabel, transportOptionProvider, transportOptionStatus } from 'src/modules/labels';
import {
  BiddingDeliveryAddressSelectionDTO,
  DeliveryAddressDTO,
  SetTransportationDTO,
  TransportationState,
  TransportOptionDTO,
  TransportOptionsDTO,
} from '../modules/generated/api';
import { setToArray } from '../modules/util';
import { SourceAddressForm } from './SourceAddressForm';
import UnitValue from './UnitValue';

type DeliveryAddressProps = {
  option?: TransportOptionDTO;
  status?: TransportationState;
  deliveryAddress?: DeliveryAddressDTO;
  deliveryAddressSelection?: BiddingDeliveryAddressSelectionDTO;
  sourceAddress?: DeliveryAddressDTO;
  bidId: string;
  changeUntil?: string;
};

const useStyles = makeStyles((theme) => ({
  displayAddress: {
    '& p': {
      margin: theme.spacing(0.5),
    },
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      padding: '10px',
    },
    paddingBottom: theme.spacing(2),
  },
  subHeading: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    fontVariant: 'small-caps',
  },
  tiles: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  editDeliveryAddress: {
    marginTop: theme.spacing(3),
  },
  SourceAddressForm: {
    marginTop: theme.spacing(1),
  },
}));

const BoughtCarDeliveryAddress = ({
  option,
  status,
  deliveryAddress,
  deliveryAddressSelection,
  sourceAddress,
  bidId,
  changeUntil,
}: DeliveryAddressProps) => {
  const { showError, showSuccess } = useSnackbarGlobal();
  const { mutate: postTransportId, isLoading } = useSetTransportInfo(bidId);
  const { t } = useTranslation();
  const classes = useStyles();

  const [editDelivery, setEditDelivery] = useState(false);
  const [editTransport, setEditTransport] = useState(false);
  const [editSource, setEditSource] = useState(false);

  const methods = useForm({ defaultValues: { deliveryAddressId: deliveryAddress?.id, transportOptionId: option?.id } });
  const { reset, getValues, watch } = methods;
  const editable = dateInFuture(changeUntil || '');
  const sourceAddressEditable = status === TransportationState.WaitingForValidSourceAddress;
  const selectedDeliveryAddressId = watch('deliveryAddressId');

  const { fetch } = useApi<TransportOptionsDTO>();
  const [transportOptions, setTransportOptions] = useState<TransportOptionsDTO>();
  const selectableDeliveryAddresses = setToArray(
    deliveryAddressSelection?.deliveryAddresses || (new Set() as Set<DeliveryAddressDTO>),
  );

  useEffect(() => {
    if (!editDelivery && !editTransport) return;
    fetch(
      ApiService.transportationController.transportationControllerGetTransportOptionsForBid(
        bidId,
        selectedDeliveryAddressId || '',
      ),
    ).then((response) => {
      if (response?.data) setTransportOptions(response.data);
    });
  }, [bidId, editDelivery, editTransport, fetch, selectedDeliveryAddressId]);

  const handleCancel = () => {
    reset();
    setEditDelivery(false);
    setEditTransport(false);
  };

  const handleSubmit = () => {
    const optionId = getValues('transportOptionId');
    const transportation: SetTransportationDTO = {
      transportOptionId: optionId,
    };
    postTransportId(transportation, {
      onSuccess: () => {
        setEditTransport(false);
        setEditDelivery(false);
        showSuccess(t('snackBar.success.update'));
      },
      onError: () => {
        showError(t('snackBar.error.update'));
      },
    });
  };

  return (
    <div className={classes.tiles}>
      <div className={classes.heading}>
        <Typography variant="h2">{t('configuration.deliveryAndTransport')} </Typography>
      </div>

      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Typography className={classes.subHeading}>{t('configuration.deliveryAddress')}</Typography>
        </Grid>
        <Grid item>
          {editable && !editDelivery && !editTransport && (
            <IconButton size="small" onClick={() => setEditDelivery(true)}>
              <Edit />
            </IconButton>
          )}
        </Grid>
      </Grid>
      {!editDelivery && deliveryAddress && (
        <div className={classes.displayAddress}>
          <p>{deliveryAddress.companyName}</p>
          <p>{deliveryAddress.street}</p>
          <p>
            {deliveryAddress.zip} {deliveryAddress.city}
          </p>
          <p>{deliveryAddress.country}</p>

          <p>
            <strong>{t('configuration.contactPerson')}: </strong>
            {deliveryAddress.contactPersonName}
          </p>
          <p>
            <strong>{t('contact.phone')}: </strong>
            {deliveryAddress.phoneNumber}
          </p>
          <p>
            <strong>{t('contact.E-Mail')}: </strong>
            {deliveryAddress.email}
          </p>
        </div>
      )}
      {editDelivery && (
        <DeliveryAddressForm
          deliveryAddresses={selectableDeliveryAddresses}
          methods={methods}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          isLoading={isLoading}
          showLink
          transportOptions={transportOptions?.options || []}
        />
      )}
      <br />
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Typography className={classes.subHeading}>{t('configuration.transportOption')}</Typography>
        </Grid>
        <Grid item>
          {editable && !editDelivery && !editTransport && (
            <IconButton size="small" onClick={() => setEditTransport(true)}>
              <Edit />
            </IconButton>
          )}
        </Grid>
      </Grid>
      {!editTransport && !editDelivery && (
        <div className={classes.displayAddress}>
          {option ? (
            <>
              <p>
                <strong>{t('configuration.transportOption')}: </strong>
                {getLabel(transportOptionProvider, option?.provider || '')}
              </p>
              {option.cost && (
                <p>
                  <strong>{t('configuration.costs')}: </strong>
                  <UnitValue value={option.cost.amount} unit={option.cost.currency} />
                </p>
              )}
              <p>
                <strong>{t('configuration.status')}: </strong>
                {getLabel(transportOptionStatus, status || '')}
              </p>
            </>
          ) : (
            <p>{t('configuration.noInfoAvailable')}</p>
          )}
        </div>
      )}
      {editTransport && (
        <div>
          <DeliveryAddressForm
            deliveryAddresses={selectableDeliveryAddresses}
            methods={methods}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            isLoading={isLoading}
            transportOptions={(function getTransportOptions() {
              if (transportOptions?.options !== undefined) {
                return transportOptions.options;
              }
              if (option !== undefined) {
                return [option];
              }
              return [];
            })()}
            onlyTransport
          />
        </div>
      )}
      <br />
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Typography className={classes.subHeading}>{t('configuration.sourceAddress')}</Typography>
        </Grid>
        <Grid item>
          {sourceAddressEditable && !editDelivery && !editTransport && !editSource && (
            <IconButton size="small" onClick={() => setEditSource(true)}>
              <Edit />
            </IconButton>
          )}
        </Grid>
      </Grid>
      {!editSource && (
        <div className={classes.displayAddress}>
          {sourceAddress ? (
            <>
              <p>{sourceAddress.companyName}</p>
              <p>{sourceAddress.street}</p>
              <p>
                {sourceAddress.zip} {sourceAddress.city}
              </p>
              <p>{sourceAddress.country}</p>

              <p>
                <strong>{t('configuration.contactPerson')}: </strong>
                {sourceAddress.contactPersonName}
              </p>
              <p>
                <strong>{t('contact.phone')}: </strong>
                {sourceAddress.phoneNumber}
              </p>
              <p>
                <strong>{t('contact.E-Mail')}: </strong>
                {sourceAddress.email}
              </p>
            </>
          ) : (
            <p>{t('configuration.noInfoAvailable')}</p>
          )}
        </div>
      )}
      {editSource && (
        <div className={classes.SourceAddressForm}>
          <SourceAddressForm
            bidId={bidId}
            sourceAddress={transformDeliveryAddressDtoToAddress(sourceAddress)}
            handleClose={() => setEditSource(false)}
          />
        </div>
      )}
    </div>
  );
};

export default BoughtCarDeliveryAddress;
