import { BidAlerts } from '@components/bid-buy/BidAlerts';
import BidBuyHeadline from '@components/bid-buy/BidBuyHeadline';
import { BidBuySubmit } from '@components/bid-buy/BidBuySubmit';
import BidBuyTimer from '@components/bid-buy/BidBuyTimer';
import BuyingTimeRadios from '@components/bid-buy/BuyingTimeRadios';
import { FirstCallOverrideSelect } from '@components/bid-buy/FirstCallOverrideSelect';
import { KVPSSelect } from '@components/bid-buy/KVPSSelect';
import { TransportSelect } from '@components/bid-buy/TransportSelect';
import DeliveryAddressSelect from '@components/dealer-table/DeliveryAddressSelect';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import { isEmpty, omitBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Control, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useRole from 'src/hooks/useRole';
import { useGetTransportOptions } from 'src/hooks/useTransportOptions';
import UserRole from 'src/types/UserRoles';
import BidForm from '../components/bid-buy/BidForm';
import CarDetailHeadline from '../components/CarDetailHeadline';
import DeleteBidDialog from '../components/DeleteBidDialog';
import { noKVPSSources, useBidBuyForm } from '../hooks/useBidBuyForm';
import { useWizard } from '../hooks/useWizard';
import { getAccountDealerId, getBiddingStatus } from '../modules/bid-buy-helpers';
import {
  BidDTO,
  BidSuccessStatus,
  DetailCarDTO,
  MarketingChannel,
  SourceType,
  WizardStep,
} from '../modules/generated/api';
import { setToArray } from '../modules/util';
import { getAccountValuationCountry } from '../modules/valuation-country-helpers';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  bidBuy: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    height: '100%',
    width: '100%',
  },
  printHide: {
    '@media print': {
      display: 'none',
    },
  },
  headlineWrapper: {
    marginBottom: theme.spacing(2),
  },
  bidAlerts: {
    marginBlockStart: theme.spacing(2),
    marginBlockEnd: theme.spacing(2),
  },
  credentialAlert: {
    marginBlockStart: theme.spacing(2),
    marginBlockEnd: theme.spacing(2),
  },
  select: {
    width: '100%',
  },
}));

const allowedValuationCountries = [
  'AT',
  'FR',
  'DE',
  'IT',
  'ES',
  'HU',
  'PL',
  'RO',
  'CZ',
  'SI',
  'HR',
  'SK',
  'PT',
  'SE',
  'BG',
  'AL',
  'MK',
  'XK',
];

type BidBuyProps = {
  car: DetailCarDTO;
  refresh: () => Promise<void>;
};

const BidBuy = ({ car, refresh }: BidBuyProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { hasRole } = useRole();

  const {
    form: { getValues, control, watch, setValue },
    form,
    resetAndRefetch,
    fetchCalculation,
  } = useBidBuyForm(car);
  const { isWizardConfirmed } = useWizard();
  const [bidForDeletion, setBidForDeletion] = useState<BidDTO | undefined>();
  const actualBidSuccessState = getBiddingStatus(car.bid);
  const [bidEditMode, setBidEditMode] = useState(true);
  const marketingChannel = car.marketingChannel || [];
  const supportsBidding = marketingChannel.includes(MarketingChannel.Bid);
  const supportsBuying = marketingChannel.includes(MarketingChannel.Fixed);
  const onlyBuying = !supportsBidding && supportsBuying;
  const isNoKVPSCar = noKVPSSources.includes(car.source!); // Especially for UCS -> No KVPS needed
  // Global form-independent errors that block the input form and display an alert

  // First call override, if other dealer than self is selected then kvps & delivery address are hidden to avoid errors
  const overrideFirstCallActive = watch('overrideFirstCallCascade') === 'yes';
  const overrideFirstCallDealer = watch('overridingFirstCallCascadeDealer');
  const overrideFirstCallDealerOther =
    overrideFirstCallDealer !== undefined && !getAccountDealerId()?.includes(overrideFirstCallDealer);

  const errors = omitBy(
    {
      dealerAddressNotSet: !isWizardConfirmed(WizardStep.DealerAddress),
      credentialsMissing: !car.kvps?.length && !isNoKVPSCar,
      illegalAccountValuationCountry: !allowedValuationCountries.includes(getAccountValuationCountry(true)),
    },
    (pred) => !pred,
  );
  const hasErrors = !isEmpty(errors);
  const allowedDeliveryAddressIds = car.deliveryAddressSelection?.selectedAddressByKVPS?.[getValues('kvps')] || [];
  const toggleEdit = () => {
    if (bidEditMode) resetAndRefetch(); // When leaving edit mode without submitting formState must be reset
    setBidEditMode((prevState) => !prevState);
  };
  const onClose = () => setBidForDeletion(undefined);

  useEffect(() => {
    setBidEditMode(
      !(
        actualBidSuccessState === BidSuccessStatus.Bought ||
        actualBidSuccessState === BidSuccessStatus.Sent ||
        actualBidSuccessState === BidSuccessStatus.Scheduled
      ) && !hasErrors,
    );
  }, [actualBidSuccessState, hasErrors, car.bid?.bids]);

  const isFirstCallCar =
    car.virtualSources &&
    (car.virtualSources.includes(SourceType.RealFirstCallOwn) ||
      car.virtualSources.includes(SourceType.RealFirstCallOther));

  const deliveryId = watch('deliveryAddress');
  const kvps = watch('kvps');
  const dataIsMissing = !car.source || !car.carId || !deliveryId || !kvps;
  const {
    data: transportOptions,
    isLoading: transportOptionsLoading,
    refetch,
  } = useGetTransportOptions(car.source || SourceType.Vwfs, car.carId || '', deliveryId || '', kvps || '');

  // when delivery address or kvps changes, reset selected transport option
  useEffect(() => {
    setValue('selectedTransportOption', undefined, { shouldValidate: true });
  }, [deliveryId, kvps, setValue]);

  return (
    <FormProvider {...form}>
      <Paper elevation={3} className={classes.bidBuy}>
        <div className={classes.headlineWrapper}>
          <BidBuyHeadline
            bidEditMode={bidEditMode}
            actualBidSuccessState={actualBidSuccessState}
            toggleEdit={toggleEdit}
            onDelete={() => setBidForDeletion(car.bid)}
          />
          <div className={classes.header}>
            <CarDetailHeadline
              title={`${
                (supportsBidding && actualBidSuccessState !== BidSuccessStatus.Bought && t('common.toBid')) || ''
              }${
                (supportsBidding && supportsBuying && actualBidSuccessState !== BidSuccessStatus.Bought && ' & ') || ''
              }${(supportsBuying && t('common.toBuy')) || ''}`}
            />
            <div>
              <Grid container direction="column">
                {!isNoKVPSCar && !overrideFirstCallDealerOther && (
                  <Grid item>
                    <KVPSSelect
                      rules={{ required: !isNoKVPSCar }}
                      kvps={car.kvps}
                      disabled={!bidEditMode || isNoKVPSCar || overrideFirstCallDealerOther}
                      className={classes.select}
                    />
                  </Grid>
                )}
                {!overrideFirstCallDealerOther && (
                  <Grid item>
                    <DeliveryAddressSelect
                      name="deliveryAddress"
                      rules={{ required: true }}
                      control={control}
                      deliveryAddresses={setToArray(car.deliveryAddressSelection?.deliveryAddresses).filter(
                        (address) => (isNoKVPSCar ? true : allowedDeliveryAddressIds.includes(address.id as string)),
                      )}
                      disabled={!bidEditMode || overrideFirstCallDealerOther}
                      className={classes.select}
                      setValue={setValue}
                    />
                  </Grid>
                )}
                {car.source === SourceType.Vwfs && !car.packageId && (
                  <TransportSelect
                    source={car.source}
                    options={transportOptions?.options}
                    loading={transportOptionsLoading}
                    dataIsMissing={dataIsMissing}
                    disabled={!bidEditMode}
                    className={classes.select}
                    refetch={refetch}
                  />
                )}
                {overrideFirstCallActive && (
                  <Grid item>
                    <FirstCallOverrideSelect bids={car.bidStateInfo?.otherCurrentBids} className={classes.select} />
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
          <div className={classes.printHide}>
            <BidBuyTimer car={car} refresh={refresh} />
          </div>
        </div>

        <BidAlerts car={car} errors={errors} className={classes.bidAlerts} onlyBuying={onlyBuying} />

        {supportsBidding && car.source && car.carId && actualBidSuccessState !== BidSuccessStatus.Bought && (
          <BidForm car={car} bidEditMode={bidEditMode} fetchCalculation={fetchCalculation} />
        )}

        {supportsBuying && hasRole(UserRole.ADMIN) && isFirstCallCar && (
          <div style={{ width: '100%', marginTop: 12, marginBottom: 12 }}>
            <BuyingTimeRadios
              name="overrideFirstCallCascade"
              control={control as unknown as Control}
              activateForm={bidEditMode}
            />
          </div>
        )}

        <BidBuySubmit
          car={car}
          supportsBuying={supportsBuying}
          supportsBidding={supportsBidding}
          buyPrice={car.potential?.base?.amount}
          actualBidSuccessState={actualBidSuccessState}
          refresh={refresh}
          bidEditMode={bidEditMode}
          errors={errors}
        />
      </Paper>
      {bidForDeletion && <DeleteBidDialog onClose={onClose} bidForDeletion={bidForDeletion} reload={refresh} />}
    </FormProvider>
  );
};
export default BidBuy;
