import { makeStyles, Paper } from '@material-ui/core';
import { useGetSelectedTransportOption } from 'src/hooks/useTransportOptions';
import { DetailCarDTO } from '../modules/generated/api';
import BoughtCarDeliveryAddress from './BoughtCarDeliveryAddress';

const useStyles = makeStyles((theme) => ({
  tiles: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  center: {
    textAlign: 'center',
  },
}));

type CarDetailBoughtCarDeliveryAddressProps = {
  car: DetailCarDTO;
};
const CarDetailBoughtCarDeliveryAddress = ({ car }: CarDetailBoughtCarDeliveryAddressProps) => {
  const classes = useStyles();
  const bidId = car.bid?.id;
  if (!bidId) return null;
  const { data: transportInfo } = useGetSelectedTransportOption(bidId);

  return (
    <Paper elevation={3} className={classes.tiles}>
      {bidId && (
        <BoughtCarDeliveryAddress
          deliveryAddress={transportInfo?.deliveryAddress}
          deliveryAddressSelection={car.deliveryAddressSelection}
          status={transportInfo?.status}
          option={transportInfo?.option}
          bidId={bidId}
          changeUntil={transportInfo?.deliveryAddressChangeUntil}
          sourceAddress={transportInfo?.sourceAddress}
        />
      )}
    </Paper>
  );
};

export default CarDetailBoughtCarDeliveryAddress;
