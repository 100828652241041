import DeliveryAddressSelect from '@components/dealer-table/DeliveryAddressSelect';
import { Button, CircularProgress, Grid, IconButton, makeStyles } from '@material-ui/core';
import { Cancel, OpenInNew, Save } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { navigate } from '@reach/router';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RoutePaths } from '../constants';
import { DeliveryAddressDTO, TransportOptionDTO } from '../modules/generated/api';
import { TransportSelectPrefilled } from './bid-buy/TransportSelect';

type DeliveryAddressFormProps = {
  deliveryAddresses: DeliveryAddressDTO[];
  isLoading?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  methods: UseFormReturn<{ deliveryAddressId: string | undefined; transportOptionId: string | undefined }>;
  showLink?: boolean;
  transportOptions?: TransportOptionDTO[];
  onlyTransport?: boolean;
};

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: theme.spacing(2),
  },
}));

const DeliveryAddressForm = ({
  onCancel,
  onSubmit,
  isLoading,
  methods,
  deliveryAddresses = [],
  showLink,
  transportOptions = [],
  onlyTransport = false,
}: DeliveryAddressFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const transportId = methods.watch('transportOptionId');
  return (
    <FormProvider {...methods}>
      <Grid item container alignItems="center">
        <Grid container spacing={1}>
          {!onlyTransport && (
            <>
              <Grid item xs={showLink ? 10 : 12}>
                <DeliveryAddressSelect
                  fullWidth
                  name="deliveryAddressId"
                  deliveryAddresses={deliveryAddresses}
                  control={methods.control}
                />
              </Grid>
              {showLink && (
                <Grid item xs={2}>
                  <IconButton onClick={() => navigate(RoutePaths.deliveryAddress)}>
                    <OpenInNew />
                  </IconButton>
                </Grid>
              )}
              <Alert severity="info">{t('configuration.resetTransportOption')}</Alert>
            </>
          )}
          <Grid item xs={10}>
            <TransportSelectPrefilled name="transportOptionId" options={transportOptions} control={methods.control} />
          </Grid>
        </Grid>
        <Grid container item spacing={1} className={classes.buttons}>
          <Grid item>
            <Button color="secondary" disabled={isLoading} endIcon={<Cancel />} onClick={onCancel} variant="contained">
              {t('common.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              disabled={isLoading || !transportId}
              endIcon={isLoading ? <CircularProgress size={20} /> : <Save />}
              type="submit"
              variant="contained"
              onClick={onSubmit}
            >
              {t('common.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default DeliveryAddressForm;
