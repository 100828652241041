import UnitValue from '@components/UnitValue';
import { CircularProgress, makeStyles, MenuItem, Select } from '@material-ui/core';
import { ReactElement, useEffect } from 'react';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BidBuyFormType } from 'src/hooks/useBidBuyForm';
import { getLabel, transportOptionProvider } from 'src/modules/labels';
import { SourceType, TransportOptionDTO } from '../../modules/generated/api';

const useStyles = makeStyles((theme) => ({
  loadingIcon: {
    position: 'absolute',
    right: theme.spacing(3),
  },
}));

type TransportSelectProps = {
  options?: TransportOptionDTO[];
  className?: string;
  disabled?: boolean;
  refetch: () => void;
  source?: SourceType;
  dataIsMissing?: boolean;
  loading?: boolean;
};

export const TransportSelect = ({
  options = [],
  refetch,
  className,
  disabled,
  source,
  dataIsMissing,
  loading = false,
}: TransportSelectProps): ReactElement | null => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { control, watch } = useFormContext<BidBuyFormType>();
  const deliveryId = watch('deliveryAddress');

  useEffect(() => {
    if (deliveryId) {
      refetch();
    }
  }, [deliveryId, refetch]);

  return (
    <Controller
      name="selectedTransportOption"
      control={control}
      rules={{ required: source === SourceType.Vwfs }}
      render={({ field }) => (
        <Select
          {...field}
          disabled={disabled || dataIsMissing || loading}
          endAdornment={
            loading && !dataIsMissing ? (
              <CircularProgress className={classes.loadingIcon} size={14} color="secondary" />
            ) : (
              ''
            )
          }
          variant="standard"
          className={className}
          value={field.value || 'empty'}
        >
          <MenuItem value="empty" disabled>
            {t('bids.transportOptionSelect')}
          </MenuItem>
          {options?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <div style={{ display: 'flex', width: '100%', gap: 5, alignItems: 'center' }}>
                {getLabel(transportOptionProvider, option.provider || '')}
                {option.cost && (
                  <span>
                    (
                    <UnitValue value={option.cost.amount} unit={option.cost.currency} />)
                  </span>
                )}
              </div>
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

type TransportSelectPrefilledProps = {
  control: Control<any>;
  options: TransportOptionDTO[];
  name: string;
};

export const TransportSelectPrefilled = ({
  options,
  control,
  name,
}: TransportSelectPrefilledProps): ReactElement | null => {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select {...field} variant="standard" value={field.value || 'empty'} fullWidth>
          <MenuItem value="empty" disabled>
            {t('bids.transportOptionSelect')}
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {getLabel(transportOptionProvider, option.provider || '')}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};
